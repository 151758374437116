var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { Locale } from '../common/enums/Locale';
import Providers from './components/Providers';
import 'intersection-observer';
export var safeParse = function (str, name) {
    if (str === void 0) { str = '{}'; }
    if (name === void 0) { name = ''; }
    try {
        return JSON.parse(str);
    }
    catch (error) {
        if (process.env.STAGE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
            // eslint-disable-next-line no-console
            console.warn("Couldn't parse data for Component \"".concat(name, "\":\n\n").concat(error));
        }
        return {};
    }
};
export var withProviders = function (Component, container, name) {
    var _a = container.dataset, _b = _a.locale, locale = _b === void 0 ? Locale.De_De_H24 : _b, componentLocales = _a.locales, initialState = _a.initialState;
    var state = safeParse(initialState, name);
    var locales = safeParse(componentLocales, name);
    // Clean-up the DOM from state if not running in dev server
    if (!(module === null || module === void 0 ? void 0 : module.hot)) {
        container.removeAttribute('data-locale');
        container.removeAttribute('data-locales');
        container.removeAttribute('data-initial-state');
    }
    return (React.createElement(Providers, { locale: locale, locales: locales },
        React.createElement(Component, __assign({}, state))));
};
var intersectionElementsMap = new Map();
var intersectionObserver = new IntersectionObserver(function (entries) {
    entries.forEach(function (entry) {
        if (entry.isIntersecting || entry.intersectionRatio > 0) {
            var target = entry.target;
            if (intersectionElementsMap.has(target)) {
                var _a = intersectionElementsMap.get(target), Component = _a.Component, name = _a.name;
                intersectionElementsMap.delete(target);
                intersectionObserver.unobserve(target);
                immediateHydrateComponent(Component, target, name);
                if (process.env.STAGE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
                    // eslint-disable-next-line no-console
                    console.log("Lazy hydrated Component \"".concat(name, "\" has intersected viewport"));
                }
            }
            else if (process.env.STAGE_ENV !== 'production') {
                // eslint-disable-next-line no-console
                console.error('Lazy hydrated Component has intersected viewport but it cannot be found in intersectionElementsMap.');
            }
        }
    });
});
function lazyHydrateComponent(Component, container, name) {
    intersectionElementsMap.set(container, { Component: Component, name: name });
    intersectionObserver.observe(container);
    container.removeAttribute('data-lazy-hydrate');
}
function immediateHydrateComponent(Component, container, name) {
    var componentWithProviders = withProviders(Component, container, name);
    if (container.childElementCount > 0) {
        hydrateRoot(container, componentWithProviders);
    }
    else {
        var root = createRoot(container);
        root.render(componentWithProviders);
    }
}
export default function hydrate(Component, root, name) {
    var containers = document.querySelectorAll(root);
    if (containers.length > 0) {
        containers.forEach(function (container) {
            if (container.dataset.hasOwnProperty('lazyHydrate')) {
                lazyHydrateComponent(Component, container, name);
            }
            else {
                immediateHydrateComponent(Component, container, name);
            }
        });
    }
    else {
        if (process.env.STAGE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
            // eslint-disable-next-line no-console
            console.warn("Couldn't find any root node \"".concat(root, "\" for Component \"").concat(name, "\""));
        }
    }
}
