var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { ScaleAliasPrefix } from '../enums/ScaleAlias';
import { generateScaleAliases } from '../utils/scaleAliases';
import figmaColors from './colors';
import opacity from './sys/opacity';
/**
 * Based of the Design System update research made by the Frontend Team
 * https://home24.atlassian.net/wiki/spaces/TT/pages/222167527/Design+system+update+research
 * We decided to start a process of updating our design system using `Scale Aliases`
 * a feature of the `styled-system` library.
 *
 * Based on this decision, initially, any new values ​​that need to be added to the style properties
 * `space`, `fontSizes`, and `lineHeights` must be added to the `extendedProps` property below.
 */
var extendedProps = {
    space: [2, 6, 10, 12, 20, 40],
    fontSizes: [10, 20, 22, 28],
    lineHeights: ['14px', '18px', '22px', '28px', '36px'],
};
export var breakpoints = ['632px', '984px', '1392px'];
/**
 * Scale Aliases are generated for the following style properties below:
 * `space`, `fontSizes` and `lineHeights`.
 */
var space = generateScaleAliases(ScaleAliasPrefix.Space, [0, 4, 8, 16, 24, 32, 48, 64, 96, 128, 192, 256, 320], extendedProps.space);
var fontSizes = generateScaleAliases(ScaleAliasPrefix.FontSizes, [11, 12, 13, 14, 16, 18, 24, 32, 40, 48], extendedProps.fontSizes);
var lineHeights = generateScaleAliases(ScaleAliasPrefix.LineHeights, [1, '16px', '20px', '24px', '32px', '40px', '48px', '56px', '64px'], extendedProps.lineHeights);
var fonts = {
    'proxima-nova': 'proxima-nova, Arial, Helvetica, sans-serif',
    fallback: 'Arial, Helvetica, sans-serif',
};
var colors = __assign(__assign({}, figmaColors), { overlay: {
        default: 'rgba(0, 0, 0, 0.25)',
        dark: 'rgba(44, 54, 56, 0.6)',
        light: 'rgba(0, 0, 0, 0.1)',
        white: 'rgba(255, 255, 255, 0.7)',
    } });
/**
 * @deprecated
 * Use `borderWidths` with `colors` instead
 */
var borders = [
    '', // NOTE: `borders.0` to `borders.2` are replaced with new tokens `borderWidths` and `colors`
    '', // adding the empty strings to keep the `borders.3` usages unchanged
    '',
    '1px solid #d8d8d8', // borders.3
];
var borderWidths = {
    small: '1px',
    medium: '2px',
    large: '4px',
};
/**
 * @deprecated
 * Use `shadows` instead
 */
var boxShadows = [
    'inset 0 0 0 1px #565d60',
    'inset 0 -1px 0 #e2e4e4',
    '0 1px 15px 0 rgba(13, 27, 34, 0.2)',
    '0 1px 8px 0 rgba(0, 0, 0, 0.15)',
];
var shadows = {
    top: 'inset 0 1px 0 #e2e4e4',
    bottom: 'inset 0 -1px 0 #e2e4e4',
    left: 'inset 1px 0 0 #e2e4e4',
    right: 'inset -1px 0 0 #e2e4e4',
    low: '0 1px 8px 0 rgba(0, 0, 0, .2)',
    lowBottom: '0 4px 4px 0 rgba(0, 0, 0, .1)',
    lowTop: '0 -3px 3px 0 rgba(0, 0, 0, .08)',
    high: '0 1px 15px 0 rgba(0, 0, 0, .2)',
};
var radii = {
    'x-small': '1px',
    small: '3px',
    medium: '4px',
    large: '12px',
    'x-large': '24px',
    table: '3px 3px 0px 0px',
    circle: '100%',
};
export var BrandTheme;
(function (BrandTheme) {
    BrandTheme["h24"] = "h24";
    BrandTheme["ffh"] = "ffh";
})(BrandTheme || (BrandTheme = {}));
var width = (_a = {},
    _a[1 / 5] = '20%',
    _a[1 / 4] = '25%',
    _a[1 / 3] = '33.3%',
    _a[1 / 2] = '50%',
    _a[2 / 3] = '66.6%',
    _a[3 / 4] = '75%',
    _a[1] = '100%',
    _a);
export default {
    breakpoints: breakpoints,
    space: space,
    colors: colors,
    borders: borders,
    boxShadows: boxShadows,
    fonts: fonts,
    fontSizes: fontSizes,
    lineHeights: lineHeights,
    shadows: shadows,
    radii: radii,
    opacity: opacity,
    width: width,
    borderWidths: borderWidths,
};
