export default {
    text: {
        default: '#d14327',
        hover: '#d14327',
        active: '#ab331a',
        strong: '#631101',
    },
    background: {
        default: '#f45334',
        weak: '#fbece5',
        hover: '#d14327',
        active: '#ab331a',
    },
    border: {
        default: '#f45334',
        hover: '#d14327',
        active: '#ab331a',
    },
    icon: {
        default: '#d14327',
        hover: '#d14327',
        active: '#d14327',
        strong: '#631101',
    },
};
