import { isClient } from './isClient';
export var captureMessage = function (message, context) {
    var _a, _b;
    if (isClient()) {
        (_a = window.Sentry) === null || _a === void 0 ? void 0 : _a.captureMessage(message, context && {
            tags: context,
        });
        (_b = window.newrelic) === null || _b === void 0 ? void 0 : _b.log(message, context && { customAttributes: context });
    }
};
/** @deprecated For capturing errors/exceptions, use captureException instead. For messages, use captureMessage. */
export var captureError = captureMessage;
export var captureException = function (error, captureContext) {
    var _a, _b;
    if (isClient()) {
        (_a = window.Sentry) === null || _a === void 0 ? void 0 : _a.captureException(error, captureContext);
        (_b = window.newrelic) === null || _b === void 0 ? void 0 : _b.noticeError(error);
    }
};
